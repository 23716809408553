import {
    StatReportInfoType,
    StatReportResType,
} from '../../../common/services/models/google/StatReportType';
import GoogleCode from '../../../modules/code/GoogleCode';
import {ModifyViewerType} from '../../modules/modify/Modify';
import {StatDataExtends} from '../modules/OxfordColumns';

export interface ConvertStatInfoViewDataType extends Omit<ModifyViewerType, 'title'> {
    title: string | React.ReactNode;
}

export const convertStatInfoViewData = (
    level?: string,
    data?: StatReportInfoType
): ConvertStatInfoViewDataType[] | undefined => {
    switch (level) {
        case GoogleCode.data.options.ExplorerLevel.campaign.value:
            return [
                {title: '캠페인 ID', value: data?.campaignId},
                {title: '캠페인 유형', value: data?.advertisingChannelTypeName},
            ];

        case GoogleCode.data.options.ExplorerLevel.adgroup.value:
            return [
                {title: '캠페인명', value: data?.campaignName},
                {title: '캠페인 ID', value: data?.campaignId},
                {title: '광고그룹 ID', value: data?.adGroupId},
            ];

        case GoogleCode.data.options.ExplorerLevel.ad.value:
            return [
                {title: '캠페인명', value: data?.campaignName},
                {title: '캠페인 ID', value: data?.campaignId},
                {title: '광고그룹명', value: data?.adGroupName},
                {title: '광고그룹 ID', value: data?.adGroupId},
                {title: '광고 ID', value: data?.adId},
            ];
    }
    return [];
};

export const ExplorerTitle: React.FC<{
    level?: string;
    data?: StatReportInfoType;
    search?: string;
}> = (props) => {
    switch (props.level) {
        case GoogleCode.data.options.ExplorerLevel.campaign.value:
            return <>캠페인명 : {props.data?.campaignName}</>;
        case GoogleCode.data.options.ExplorerLevel.adgroup.value:
            return <>광고그룹명 : {props.data?.adGroupName}</>;
        case GoogleCode.data.options.ExplorerLevel.ad.value:
            return (
                <>
                    {' '}
                    {props.data?.adName
                        ? `광고명 : ${props.data?.adName}`
                        : `광고 ID : ${props.data?.adId}`}
                </>
            );
    }
    return <>Unknown</>;
};

/**
 * 20230622 --> 2023-06-22
 * @param txt
 * @returns
 */
export const getStatDateFormatter = (txt: string) => {
    const tmp: string = txt.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    if (isNaN(new Date(tmp).getTime())) {
        return txt;
    }
    return new Date(tmp).format('yyyy-MM-dd');
};

/** Stat 컬럼만 Sum 처리 합니다. */
export const getStatReportTotal = (data: StatReportResType[]): StatReportResType => {
    const total: StatReportResType = {
        key: '합계',
        impressions: 0,
        clicks: 0,
        cost: 0,
        videoView: 0,
        videoView25: 0,
        videoView50: 0,
        videoView75: 0,
        videoViewEnd: 0,
        interactions: 0,
        uniqueUsers: 0,
        ctr: 0,
        vtr: 0,
        videoViewRate25: 0,
        videoViewRate50: 0,
        videoViewRate75: 0,
        videoViewRateEnd: 0,
        interactionRate: 0,
        cpm: 0,
        cpc: 0,
        cpv: 0,
        conversions: 0,
        viewThroughConversions: 0,
        inappActions: 0,
        installs: 0,
        purchase: 0,
        completeRegistration: 0,
        addToCart: 0,
        partnerOutboundClicks: 0,
        lead: 0,
        beginCheckout: 0,
        contact: 0,
        bookAppointment: 0,
        landingPageView: 0,
        conversionEngagement: 0,
        leadPhonecall: 0,
        storeSales: 0,
        storeVisit: 0,
        getDirectiongs: 0,
        importedLead: 0,
        subscribePaid: 0,
        requestQuote: 0,
        submitLeadForm: 0,
        actionDefault: 0,
        conversionEtc: 0,
        conversionsRate: 0,
        inappActionRate: 0,
        installRate: 0,
        costPerConversion: 0,
        costPerInappAction: 0,
        costPerInstall: 0,
        purchasePerClick: 0,
    };

    const entries = Object.entries(total);
    data.forEach((v) => {
        const tmp: Map<string, number> = new Map(Object.entries(v));
        entries.forEach((v2) => {
            if (v2[0] === 'key') {
                return;
            }
            v2[1] += tmp.get(v2[0]) || 0;
        });
    });

    return StatDataExtends({...total, ...Object.fromEntries(entries)});
};

export const AppendStatReportTotal = (data: StatReportResType[]): StatReportResType[] => {
    const total: StatReportResType = getStatReportTotal(data);
    data.push(total);
    return data;
};
