import {
    StatReportInfoType,
    StatReportResType,
} from '../../../common/services/models/meta/StatReportType';
import MetaCode from '../../../modules/code/MetaCode';
import {ModifyViewerType} from '../../modules/modify/Modify';
import {StatDataExtends} from '../modules/OxfordColumns';

export interface ConvertStatInfoViewDataType extends Omit<ModifyViewerType, 'title'> {
    title: string | React.ReactNode;
}

export const convertStatInfoViewData = (
    level?: string,
    data?: StatReportInfoType
): ConvertStatInfoViewDataType[] | undefined => {
    switch (level) {
        case MetaCode.data.options.ExplorerLevel.campaign.value: {
            return [{title: '캠페인 ID', value: data?.campaignId}];
        }
        case MetaCode.data.options.ExplorerLevel.adGroup.value: {
            return [
                {title: '캠페인명', value: data?.campaignName},
                {title: '캠페인 ID', value: data?.campaignId},
                {title: '광고세트 ID', value: data?.adsetId},
            ];
        }

        case MetaCode.data.options.ExplorerLevel.ad.value: {
            return [
                {title: '캠페인명', value: data?.campaignName},
                {title: '캠페인 ID', value: data?.campaignId},
                {title: '광고세트명', value: data?.adsetName},
                {title: '광고세트 ID', value: data?.adsetId},
                {title: '광고 ID', value: data?.adId},
            ];
        }
    }
    return [];
};

export const ExplorerTitle: React.FC<{
    level?: string;
    data?: StatReportInfoType;
    search?: string;
}> = (props) => {
    switch (props.level) {
        case MetaCode.data.options.ExplorerLevel.campaign.value:
            return <>캠페인명 : {props.data?.campaignName}</>;
        case MetaCode.data.options.ExplorerLevel.adGroup.value:
            return <>광고세트명 : {props.data?.adsetName}</>;
        case MetaCode.data.options.ExplorerLevel.ad.value:
            return <>광고명 : {props.data?.adName}</>;
    }
    return <>Unknown</>;
};

/**
 * 20230622 --> 2023-06-22
 * @param txt
 * @returns
 */
export const getStatDateFormatter = (txt: string) => {
    const tmp: string = txt.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    if (isNaN(new Date(tmp).getTime())) {
        return txt;
    }
    return new Date(tmp).format('yyyy-MM-dd');
};

/** Stat 컬럼만 Sum 처리 합니다. */
export const getStatReportTotal = (data: StatReportResType[]): StatReportResType => {
    const total: StatReportResType = {
        key: '합계',
        clicks: 0,
        frequency: 0,
        impressions: 0,
        reach: 0,
        result: 0,
        cost: 0,
        uniqueCpc: 0,
        reachValue: 0,
        uniqueClicks: 0,
        linkClick: 0,
        postEngagement: 0,
        postComment: 0,
        postSave: 0,
        postShare: 0,
        postReaction: 0,
        pageEngagement: 0,
        like: 0,
        photoView: 0,
        videoViewStart: 0,
        videoView25: 0,
        videoView50: 0,
        videoView75: 0,
        videoView95: 0,
        videoViewEnd: 0,
        videoPlay3s: 0,
        thruPlay: 0,
        likeValue: 0,
        videoAbove3sValue: 0,
        thruPlayValue: 0,
        purchase: 0,
        purchaseValue: 0,
        webPurchase: 0,
        webPurchaseValue: 0,
        appMobilePurchase: 0,
        appMobilePurchaseValue: 0,
        facebookPurchase: 0,
        facebookPurchaseValue: 0,
        completeRegistration: 0,
        webCompleteRegistration: 0,
        appMobileCompleteRegistration: 0,
        installs: 0,
        installsPc: 0,
        installsMobile: 0,
        addToCart: 0,
        webAddToCart: 0,
        appMobileAddToCart: 0,
        activateApp: 0,
        appMobileActivateApp: 0,
        installsValue: 0,

        ctr: 0,
        cpc: 0,
        cpm: 0,
        cpr: 0,
        uniqueCtr: 0,
        costPerUniqueClick: 0,
        costPerPageLike: 0,
        costPerVideoView3s: 0,
        costPerVideoView15s: 0,
        costPerInstall: 0,

        cvr: 0,
        vtr: 0,
        roas: 0,
        cpv: 0,
    };

    const entries = Object.entries(total);
    data.forEach((v) => {
        const tmp: Map<string, number> = new Map(Object.entries(v));
        entries.forEach((v2) => {
            if (v2[0] === 'key') {
                return;
            }
            v2[1] += tmp.get(v2[0]) || 0;
        });
    });

    return StatDataExtends({...total, ...Object.fromEntries(entries)});
};

export const AppendStatReportTotal = (data: StatReportResType[]): StatReportResType[] => {
    const total: StatReportResType = getStatReportTotal(data);
    data.push(total);
    return data;
};
